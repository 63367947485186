import { Navigate } from 'react-router-dom';
import { PROTECTED_ROUTE_SLUGS } from '../app/constants';

export function AuthRouteHelper({ children, isAuthenticated, ...rest }) {
    return isAuthenticated ? children : <Navigate to='/' />
}

export function ProtectedRouteHelper({ children, isAuthenticated, ...rest }) {
    if (!rest.emailVerified) {
        return children;
    }
    return !isAuthenticated ? children : <Navigate to='/profile' />
}