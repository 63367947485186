export const ENV_PRODUCTION = 'production';
export const BASEURL = process.env.REACT_APP_HOST_URL;
export const PREFIX = '/api';
export const USER_STATIC_TYPE_ID = 2;
export const ADMIN_STATIC_TYPE_ID = 3;
export const NODE_API_BASEURL = process.env.NODE_ENV === ENV_PRODUCTION ? process.env.REACT_APP_PROD_NODE_API : process.env.REACT_APP_NODE_API
export const NODE_API_PREFIX = '/api';
console.warn(process.env.NODE_ENV);
console.warn(NODE_API_BASEURL);
export const AUTH = {
    SIGNUP: `${BASEURL}${PREFIX}/User/SignUp/Sign-up`,
    LOGIN: `${BASEURL}${PREFIX}/User/Login/Login`,
    GENERATE_HASHCODE: `${BASEURL}${PREFIX}/User/GenerateHashCode/GenerateHashCodeForLoginPassword`,
    VERIFY_OTP: `${BASEURL}${PREFIX}/User/VerifyOTP/VerifyOTP`,
}

export const GENERAL = {
    GET_AVTAR: `${BASEURL}${PREFIX}/Avatar/GetAvatars`,
}

export const AVTAR = {
    CREATE: `${BASEURL}${PREFIX}/Avatar/CreateAvatar`,
    PUBLISH: `${BASEURL}${PREFIX}/AvatarPublicPublish/Publish`,
    PUBLIC: `${BASEURL}${PREFIX}/AvatarPublicPublish/Public`,
    DELETE: `${BASEURL}${PREFIX}/Avatar/DeleteAvatar?id={AVATAR_ID}`,
    CHECK_PUBLISH_ACCESS: `${BASEURL}${PREFIX}/AvatarPublicPublish/CheckLoginUserAccess`,
    EDIT: `${BASEURL}${PREFIX}/Avatar/GetAvatarById`,
    UPLOAD_PROFILE_SECTION: `${BASEURL}${PREFIX}/Avatar/UploadAvatarProfileSection`,
    UPDATE_PROFILE_SECTION: `${BASEURL}${PREFIX}/Avatar/UpdateAvatarProfileSection`,
    UPLOAD_AVATAR_STORY: `${BASEURL}${PREFIX}/Avatar/UploadAvatarStory`,
    UPDATE_AVATAR_STORY: `${BASEURL}${PREFIX}/Avatar/UpdateAvatarStory`,
    UPLOAD_AVATAR_ALBUM: `${BASEURL}${PREFIX}/Avatar/UploadAvatarAlbum`,
    UPDATE_AVATAR_ALBUM: `${BASEURL}${PREFIX}/Avatar/UpdateAvatarAlbum`,
    UPDATE_AVATAR_DP: `${BASEURL}${PREFIX}/Avatar/UpdateAvatarDP`,
    PROFILE: `${BASEURL}${PREFIX}/User/UserProfile/GetUserProfile`,
    CHAT_RESPONSE: `${BASEURL}${PREFIX}/UserBotChat/GetBotResponse`,
    AUDIO_CHAT_RESPONSE: `${BASEURL}${PREFIX}/UserBotChat/GetWithVoice`,
    ADD_SUBJECT: `${BASEURL}${PREFIX}/Avatar/AddSubject`,
    GET_SUBJECT: `${BASEURL}${PREFIX}/Avatar/GetSubjects`,
    GET_AVATAR_MEDIA: `${BASEURL}${PREFIX}/Avatar/GetAvatarMedia`,
    GET_AVATAR_DATE_FILTER: `${BASEURL}${PREFIX}/Avatar/GetDatesForMediaFilter`,
    GET_AVATAR_SUBJECT_FILTER: `${BASEURL}${PREFIX}/Avatar/GetDatesForMediaFilter`,
    UPLOAD_PROFILE_IMAGE: `${BASEURL}${PREFIX}/User/UserProfile/UploadProfileImage`,
    UPDATE_USER_PROFILE: `${BASEURL}${PREFIX}/User/UserProfile/UpdateUserProfile`,
    GOOGLE_LOGIN: `${BASEURL}${PREFIX}/User/GoogleLogin/Login`,
    GOOGLE_LOGIN_RESPONSE: `${BASEURL}${PREFIX}/User/GoogleResponse/Response`,
    FAVORITE_AVATARS: `${NODE_API_BASEURL}${NODE_API_PREFIX}/GetFavoritebyUid/{UID}`,
    ALL_FAVORITE_AVATARS: `${NODE_API_BASEURL}${NODE_API_PREFIX}/GetAllFavoritebyUid/{UID}`,
    REMOVE_FAVORITE_AVATAR: `${NODE_API_BASEURL}${NODE_API_PREFIX}/RemoveFavoratebyUid`,
    SET_FAVORITE_AVATAR: `${NODE_API_BASEURL}${NODE_API_PREFIX}/SetFavoratebyUid`,
    SET_USER_QUESTIOS: `${NODE_API_BASEURL}${NODE_API_PREFIX}/SetUserQuestions`,
    RECENT_VISIT: `${NODE_API_BASEURL}${NODE_API_PREFIX}/GetRecentbyUid/{UID}`,
    SET_RECENT_VISIT: `${NODE_API_BASEURL}${NODE_API_PREFIX}/SetRecentbyUid`,
    SPEECH_TO_TEXT: `${NODE_API_BASEURL}${NODE_API_PREFIX}/SpeechToText`,
    TEXT_TO_SPEECH: `${NODE_API_BASEURL}${NODE_API_PREFIX}/TextToSpeech`,
}

export const ADMIM = {
    FAMILY_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/Question/GetFamilyQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/Question/UpdateFamilyQuestion`,
        ADD: `${BASEURL}${PREFIX}/Question/AddFamilyQuestion`,
        DELETE: `${BASEURL}${PREFIX}/Question/DeleteFamilyQuestion`,
    },
    STORY_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/Question/GetStoryQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/Question/UpdateStoryQuestion`,
        ADD: `${BASEURL}${PREFIX}/Question/AddStoryQuestion`,
        DELETE: `${BASEURL}${PREFIX}/Question/DeleteStoryQuestion`,
    }
}

export const ADMIN = {
    LOGIN: `${BASEURL}${PREFIX}/Avatar/CreateAvatar`,
    FAMILY_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/Question/GetFamilyQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/Question/UpdateFamilyQuestion`,
        ADD: `${BASEURL}${PREFIX}/Question/AddFamilyQuestion`,
        DELETE: `${BASEURL}${PREFIX}/Question/DeleteFamilyQuestion`,
    },
    STORY_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/Question/GetStoryQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/Question/UpdateStoryQuestion`,
        ADD: `${BASEURL}${PREFIX}/Question/AddStoryQuestion`,
        DELETE: `${BASEURL}${PREFIX}/Question/DeleteStoryQuestion`,
    },
    IDENTITY_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/Question/GetIdentityQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/Question/UpdateIdentityQuestion`,
        ADD: `${BASEURL}${PREFIX}/Question/AddIdentityQuestion`,
        DELETE: `${BASEURL}${PREFIX}/Question/DeleteIdentityQuestion`,
    },
    INTERCEPT_QUESTION: {
        LIST: `${BASEURL}${PREFIX}/InterceptUserQuestion/GetQuestion`,
        UPDATE: `${BASEURL}${PREFIX}/InterceptUserQuestion/UpdateQuestion`,
        ADD: `${BASEURL}${PREFIX}/InterceptUserQuestion/InsertQuestion`,
        DELETE: `${BASEURL}${PREFIX}/InterceptUserQuestion/DeleteQuestion`,
    },
}

export const MEDIA = {
    UPLOAD_IMAGE: `${BASEURL}${PREFIX}/Media/UploadImage`,
};

export const profile = {
    //'abc':'/xyz/',
}
