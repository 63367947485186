import { STORAGE_INDEXES } from '../../app/constants';
export function defaultInitialState(LOCAL_STORAGE_KEY) {
    let DEFAULT_INITIAL_STATES = {};
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.IS_AUTHENTICATED] = false;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.EMAIL_VERIFIED] = false;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.LOADER] = false;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.ERROR_MESSAGE] = null;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.SUCCESS_MESSAGE] = null;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.EMAIL] = null;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.ACCESS_TOKEN] = null;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.USER_DATA] = {};
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(DEFAULT_INITIAL_STATES));
    return DEFAULT_INITIAL_STATES;
}

export function authUserData(data) {
    let AUTH_USER_DATA = {};
    AUTH_USER_DATA[STORAGE_INDEXES.IS_AUTHENTICATED] = true;
    AUTH_USER_DATA[STORAGE_INDEXES.EMAIL_VERIFIED] = data.isOTPVerified;
    AUTH_USER_DATA[STORAGE_INDEXES.LOADER] = false;
    AUTH_USER_DATA[STORAGE_INDEXES.EMAIL] = data.emailAddress;
    AUTH_USER_DATA[STORAGE_INDEXES.ERROR_MESSAGE] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.SUCCESS_MESSAGE] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.ACCESS_TOKEN] = data.token;
    AUTH_USER_DATA[STORAGE_INDEXES.USER_DATA] = data;
    localStorage.setItem(STORAGE_INDEXES.APP_STORAGE, JSON.stringify(AUTH_USER_DATA))
    return AUTH_USER_DATA;
}

export function updateOtpVerification() {
    let AUTH_USER_DATA = JSON.parse(localStorage.getItem(STORAGE_INDEXES.APP_STORAGE));
    AUTH_USER_DATA[STORAGE_INDEXES.EMAIL_VERIFIED] = true;
    AUTH_USER_DATA[STORAGE_INDEXES.USER_DATA]['isOTPVerified'] = true;
    AUTH_USER_DATA[STORAGE_INDEXES.LOADER] = false;
    AUTH_USER_DATA[STORAGE_INDEXES.ERROR_MESSAGE] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.SUCCESS_MESSAGE] = null;
    localStorage.setItem(STORAGE_INDEXES.APP_STORAGE, JSON.stringify(AUTH_USER_DATA))
    return AUTH_USER_DATA;
}

export function loginFailure(errorMessage) {
    let AUTH_USER_DATA = {};
    AUTH_USER_DATA[STORAGE_INDEXES.IS_AUTHENTICATED] = false;
    AUTH_USER_DATA[STORAGE_INDEXES.LOADER] = false;
    AUTH_USER_DATA[STORAGE_INDEXES.EMAIL] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.NAME] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.ERROR_MESSAGE] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.SUCCESS_MESSAGE] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.ACCESS_TOKEN] = null;
    AUTH_USER_DATA[STORAGE_INDEXES.USER_ID] = 0;
    AUTH_USER_DATA[STORAGE_INDEXES.USER_ROLE] = null;
    return AUTH_USER_DATA;
}

export function defaultInitialChatState(LOCAL_STORAGE_KEY) {
    let DEFAULT_INITIAL_STATES = {};
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.AVATAR_ID] = 0;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.CONVERSATION] = [];
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.AUDIO_CONVERSATION] = [];
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.CHAT_MODE] = STORAGE_INDEXES.CHAT_MODE_TEXT;
    DEFAULT_INITIAL_STATES[STORAGE_INDEXES.QUERY_ID] = 0;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(DEFAULT_INITIAL_STATES));
    return DEFAULT_INITIAL_STATES;
}

export function defaultInitialFavouriteAvatarState(LOCAL_STORAGE_KEY) {
    let DEFAULT_INITIAL_STATES = {
        status: false,
        isLoading: false,
        errorMessage: null,
        avatarList: [],
        avatarListIds: []
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(DEFAULT_INITIAL_STATES));
    return DEFAULT_INITIAL_STATES;
}

export function defaultInitialRecentVisitState(LOCAL_STORAGE_KEY) {
    let DEFAULT_INITIAL_STATES = {
        status: false,
        isLoading: false,
        errorMessage: null,
        avatarList: []
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(DEFAULT_INITIAL_STATES));
    return DEFAULT_INITIAL_STATES;
}