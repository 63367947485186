const actions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  AUTH_MESSAGE: 'AUTH_MESSAGE',
  GET_AUTH_USER: 'GET_AUTH_USER',
  GET_AUTH_USER_SUCCESS: 'GET_AUTH_USER_SUCCESS',
  GET_AUTH_USER_FAILURE: 'GET_AUTH_USER_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  AUTH_PROCESS_ERROR: 'AUTH_PROCESS_ERROR',
  EMAIL_VERIFY_PROCESS: 'EMAIL_VERIFY_PROCESS',
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  OPEN_AUTH_MODAL: 'OPEN_AUTH_MODAL',
  CLOSE_AUTH_MODAL: 'CLOSE_AUTH_MODAL',
  API_PROCESS: 'API_PROCESS',
  PROCESS_INIT: 'PROCESS_INIT',
  API_PROCESS_FAILURE: 'API_PROCESS_FAILURE',
  API_PROCESS_SUCCESS: 'API_PROCESS_SUCCESS',
  TOAST_PROCESS: 'TOAST_PROCESS',
  TOAST_STATUS: 'TOAST_STATUS',
  TOAST_TITLE: 'TOAST_TITLE',
  TOAST_TYPE: 'TOAST_TYPE',
  TOAST_MESSAGE: 'TOAST_MESSAGE',
  UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',
}

export const CHAT_ACTIONS = {
  CHAT_INIT: 'CHAT_INIT',
  CHAT_CONVERSATION_INIT: 'CHAT_CONVERSATION_INIT',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  UPDATE_AUDIO_CONVERSATION: 'UPDATE_AUDIO_CONVERSATION',
  RESET_CHAT: 'RESET_CHAT',
  UPDATING_QUERY_ID: 'UPDATING_QUERY_ID',
  CHANGE_CHAT_MODE: 'CHANGE_CHAT_MODE',
}

export const FAVOURIE_AVATARS_ACTION = {
  INIT: 'FA_INIT',
  RESET: 'FA_RESET',
  IS_LOADING: 'FA_IS_LOADING',
  UPDATE_AVATAR_LIST: 'FA_UPDATE_AVATAR_LIST',
  ADD_AVATAR: 'FA_ADD_AVATAR',
}

export const RECENT_AVATARS_ACTION = {
  INIT: 'RA_INIT',
  RESET: 'RA_RESET',
  IS_LOADING: 'RA_IS_LOADING',
  UPDATE_AVATAR_LIST: 'RA_UPDATE_AVATAR_LIST',
  ADD_AVATAR: 'RA_ADD_AVATAR',
}

export const PHOTO_ALBUM_ACTIONS = {
  FILTER_INIT: 'FILTER_INIT',
  SUBJECT_IS_LOADING: 'SUBJECT_IS_LOADING',
  SUBJECT_RESET_LOADING: 'SUBJECT_RESET_LOADING',
  SUBJECT_PROCESS_FAILED: 'SUBJECT_PROCESS_FAILED',
  HANDLE_SUBJECT_ID: 'HANDLE_SUBJECT_ID',
  DATE_IS_LOADING: 'DATE_IS_LOADING',
  DATE_RESET_LOADING: 'DATE_RESET_LOADING',
  HANDLE_DATE_YEAR: 'HANDLE_DATE_YEAR',
  HANDLE_MONTH_YEAR: 'HANDLE_MONTH_YEAR',
  HANDLE_FILTER_TYPE_CHANGE: 'HANDLE_FILTER_TYPE_CHANGE',
  HANDLE_SLECTED_AVATAR_STATUS: 'HANDLE_SLECTED_AVATAR_STATUS',
  // SUBJECT_PROCESS_FAILED: 'SUBJECT_PROCESS_FAILED',
  // HANDLE_SUBJECT_ID: 'HANDLE_SUBJECT_ID',
}

export default actions;

export const login = (email, password, staticTypeId, callback) => ({
  type: actions.LOGIN,
  payload: { email, password, staticTypeId, callback },
});

export const signup = (firstName, lastName, emailAddress, password, terms, staticTypeId, isInvited = false, callback) => ({
  type: actions.SIGNUP,
  index: actions.SIGNUP,
  payload: { firstName, lastName, emailAddress, password, terms, staticTypeId, isInvited },
  FnCallback: callback,
});

export const verifyOtp = (otp) => ({
  type: actions.VERIFY_OTP,
  payload: { otp }
});

export const logout = () => ({ type: actions.LOGOUT, payload: null });

export const ACTION_SERVICES = (TYPE, LOADER = true, MESSAGE = null) => ({
  type: actions[TYPE],
  payload: { loader: LOADER, errorMessage: MESSAGE }
});

export const ACTION_SUB_SERVICES = (TYPE, LOADER = true, MESSAGE = null) => ({
  type: actions[TYPE],
  payload: { loader: LOADER, errorMessage: MESSAGE }
});