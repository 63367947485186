import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import './footer.css';
function Footer(props) {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const inerval = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => {
            clearInterval(inerval);
        }
    }, [])

    return (
        <>
            {/* <div className="cursor">
            <svg width="30" height="30px" viewBox="0 0 30px 30px">
                <circle cx="15" cy="15" r="10" stroke="#fff" strokeWidth="2" fill="transparent"></circle>
            </svg>
           </div> */}
            <footer className="spacer-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto text-white text-center">
                            <div className="footer__content">
                                <Link className="navbar-brand" to="/">
                                    <img src="/images/logo.png" alt="logo" />
                                </Link>
                                <div>&copy;2024 Afterlifeai. All Rights Reserved</div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;