export const ENUM_AVTAR = {
    GENDER: {
        MALE: 0,
        FEMALE: 1,
    },
    TYPE:{
        HUMAN:0,
        CAT:1,
        DOG:2,
    },
    STORY: {
        SAD: 0,
        VACATION: 1,
        CHILDHOOD: 2,
        ADULT: 3,
        IMPORTANTLIFE: 4,
        SCHOOL: 5,
        WORK: 6,
    },
    SECTION_TITLE: {
        EDUCATION: 0,
        WORK: 1,
        WORKHISTORY: 1,
        HOBBY: 2,
    },
    STATUS: {
        UNDER_REVIEW: 0,
        REVIEWED: 1,
    },
    SUBSECTION_TITLE: {
        SCHOOL: 1,
        UNIVERSITY: 2,
    },
    FAMILY_QUESTION: {
        FATHER: 0,
        MOTHER: 1,
        CHILDREN: 2,
        SELF: 3,
        FRIEND: 4,
        REPETITION:{
            REPEATABLE:'Repeatable',
            NON_REPEATABLE:'NonRepeatable',
        },
        BELONGS_TO:{
            FATHER:'Father',
            MOTHER:'Mother',
            CHILDREN:'Children',
            SELF:'Self',
            FRIEND:'Friend',
        }
    },
    STORY_QUESTION: {
        FATHER: 0,
        MOTHER: 1,
        CHILDREN: 2,
        SELF: 3,
        FRIEND: 4,
        REPETITION:{
            REPEATABLE:'Repeatable',
            NON_REPEATABLE:'NonRepeatable',
        },
        BELONGS_TO:{
            SAD_STORY:'SadStory',
            HAPPY_STORY:'HappyStory',
            THRILL_STORY:'ThrillStory',
            CHILDHOOD_STORY:'ChildhoodStory',
            WORK_STORY:'WorkStory',
            VACATION_STORY:'VacationStory',
            FAMILY_STORY:'FamilyStory',
            SCHOOL_STORY:'SchoolStory',
            FRIENDS_STORY:'FriendsStory',
            LIFE_LESSION_STORY:'LifeLessonStory',
        }
    },
    IDENTITY_QUESTION:{
        BELONGS_TO:{
            EDUCATION:'Education',
            WORK:'Work',
            HOBBY:'Hobby',
        }
    },
    INTERCEPT_QUESTION:{
        TAG:{
            NONE:'None',
            NAME:'Name',
            FATHER_NAME:'FatherName',
            MOTHER_NAME:'MotherName',
            LOVER:'Lover',
            BIRTH_PLACE:'BirthPlace',
            SCHOOL:'School',
            CHILDREN:'Children',
            WORK:'Work',
            HOBBY:'Hobby',
            CHILDHOOD_STORY:'ChildhoodStory',
            WORK_STORY:'WorkStory',
            VACATION_STORY:'VacationStory',
            SAD_STORY:'SadStory',
            HAPPY_STORY:'HappyStory',
            DOB:'DOB',
            LIFE_SUMMARY:'LifeSummary',
            DO_NORMAL_CHILDHOOD:'DoNormalChildhood',
        }
    }
}