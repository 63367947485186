import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/authenticate/actions';
import { AUTH_ROUTE_SLUGS, PUBLIC_ROUTES_SLUGS } from '../../app/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser } from '@fortawesome/free-solid-svg-icons';

function AuthNavMenu() {
    const dispatch = useDispatch();
    const { userData: { profileImage } } = useSelector(state => state.auth);
    const [isNavbarOpen, setIsNavbarOpen] = useState("collapse");

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    }

    const handleLogout = () => {
        dispatch(logout());
        setIsNavbarOpen(!isNavbarOpen);
    }

    return <>
        <ul className="navbar-nav custom-menu">
            <li className="nav-item">
                <Link className="nav-link active" to={PUBLIC_ROUTES_SLUGS.ROOT} aria-current="page">Home</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to={PUBLIC_ROUTES_SLUGS.ABOUT_US} aria-current="page">About Us</Link>
            </li>
            <li className="nav-item">
                <a href="https://discord.com/channels/1213621323138465842/1213623122016866394" target='_blank' className="nav-link" aria-current="page">Contact us</a>
            </li>

        </ul>

        <div className="logged-user">
            <Link to="/profile">
                {
                    profileImage && profileImage?.imageBlobUrl !== null
                        ? <img className="rounded-circle" src={profileImage?.imageBlobUrl} alt='profile-image' />
                        : <FontAwesomeIcon icon={faCircleUser} style={{ color: "#808ce3", fontSize: "1.7em" }} />
                }
            </Link>
        </div>

        <button className={`navbar-toggler ${isNavbarOpen ? 'collapsed' : 'togglemenu'}`} type="button" onClick={toggleNavbar}>
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`navbar-collapse navbar-collapse-div profile-dropdown collapse ${isNavbarOpen ? '' : 'show'}`}>
            <ul className="navbar-nav navbar-dropdown">
                <li className="nav-item">
                    <Link to="/profile" onClick={toggleNavbar} className="nav-link active">Profile</Link>
                </li>
                <li className="nav-item">
                    <Link to={AUTH_ROUTE_SLUGS.AVTAR.LIST} onClick={toggleNavbar} className="nav-link">Avatar</Link>
                </li>
                <li className="nav-item">
                    <Link to={AUTH_ROUTE_SLUGS.AVTAR.FAVORITE_AVATARS} onClick={toggleNavbar} className="nav-link">Favourite Avatar</Link>
                </li>
                <li className="nav-item">
                    <Link onClick={handleLogout} className="nav-link">Logout</Link>
                </li>
            </ul>
        </div>
    </>
}

export default AuthNavMenu