export const RESPONSE_MESSAGES = {
  en: {
    SUCCESS_MESSAGE: `Thank you contacting us!`,
    LOGIN_FAILURE: `Getting error while login. Please try again.`,
    ERROR_MESSAGE: `Getting error while sending your request. Please try again.`,
    UNKNOWN_ERROR_MESSAGE: `Getting error while sending your request. Please try again.`,
    TYPE_ERROR: `error`,
    TYPE_SUCCESS: `success`,
    NETWORK_ERROR: `Unable to connect with services.`,
    RESET_PASS_SUCCESS: `Password has been updated.`,
    RESET_PASS_FAILED: `Unable to reset password. Please try again.`,
    PROFILE_UPLOAD_FAILED: `Unable to update profile picture. Please try again.`,
    PROFILE_UPLOAD_SUCCESS: `Profile picture has been updated.`,
    FETCHING_USERS_LIST: `Getting error while fetching users. Please try again.`,
    FETCHING_QUESTIONS: `Getting error while fetching questions. Please try again.`,
    ERROR_UPDATING_QUESTIONS: `Getting error while updating questions. Please try again.`,
    ERROR_ADDING_QUESTIONS: `Getting error while adding question. Please try again.`,
    SUCCESS_UPDATE_QUESTIONS: `Question updated successfully`,
    SUCCESS_DELETE_QUESTIONS: `Question deleted successfully`,
    ERROR_DELETE_QUESTIONS: `Getting error while deleting question. Please try again`,
    SUCCESS_ADD_QUESTIONS: `Question added successfully.`,
    NOT_FOUND_404: `404: Service not found!.`,
    REQUEST_405: `Invalid request method!. Please contact Administration or try again after some time.`,
    ECONNABORTED: `Gettig too long to process your request. Pease try again.`,
    ERR_BAD_REQUEST: `Getting bad request error during process your request. Pease try again.`,
    SIGNUP_FAILURE: `Unable to signup at this moment. Please try again.`,
    VERIFY_OTP_FAILURE: `Unable to verify OTP at this moment. Please try again.`,
    IMAGE_UPLOAD_FAILED: `Unable to upload image. Please try again.`,
    AVTAR_CREATE_FAILED: `Unable to create avtar. Please try again.`,
    AVTAR_LIST_FAILURE: `Unable to get avtars. Please try again.`,
    GET_AVTAR_ELSE_CASE: `Unable to get avatar details. Please try again.`,
    REQUIRED_AVATAR_STORY: `At least one story has to be created.`,
    UNAUTHENTICATED_MESSAGE: `please provide a valid authorization token or your roles has been updated by admin. please logout and login again to continue.`,
    UNAUTHENTICATED_ALERT_MSG: `Your session has expired. Please log in again to continue.`,
    ERROR_OTP_VERIFICATION: `Please verify OTP.`,
    REMOVE_FAV_AVATAR: `Avatar removed as favorite.`,
  }
};