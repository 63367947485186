import { combineReducers } from 'redux';
import authenticateReducer from './authenticate/reducer'
import ChatReducer from './chatReducer';
import photoAlbumReducer from './photoAlbumReducer';
import favouriteAvatarReducer from './favouriteAvatarReducer';
import recentVisitAvatarReducer from './recentVisitAvatarReducer';

const rootReducer = combineReducers({
  auth: authenticateReducer,
  avatar_conversation: ChatReducer,
  photo_album_reducer: photoAlbumReducer,
  favourite_avatar: favouriteAvatarReducer,
  recent_avatar: recentVisitAvatarReducer,
});

export default rootReducer;