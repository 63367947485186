import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AuthModal from '../../../components/UI/Modal/Auth';
import AuthNavMenu from '../../../components/AuthNavMenu';
import actions from '../../../redux/authenticate/actions';
import { PUBLIC_ROUTES_SLUGS } from '../../../app/constants';


function NavBar() {
    const { emailVerified, isAuthenticated, openAuthModal } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        dispatch({ type: actions.CLOSE_AUTH_MODAL });
    };
    const handleShow = () => setShow(true);
    return (
        <>
            {isAuthenticated ? (
                <AuthNavMenu />
            ) : (
                <>
                    <div className="collapse navbar-collapse show" id="navbarNav">
                        <ul className="navbar-nav  ms-auto pointer-effect">
                            <li className="nav-item">
                                <Link className="nav-link" to={PUBLIC_ROUTES_SLUGS.ROOT} aria-current="page">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={PUBLIC_ROUTES_SLUGS.ABOUT_US} aria-current="page">About Us</Link>
                            </li>
                            <li className="nav-item">
                                {/* <Link className="nav-link" to={PUBLIC_ROUTES_SLUGS.CONTACT_US} aria-current="page">Contact us</Link> */}
                                <a href="https://discord.com/channels/1213621323138465842/1213623122016866394" target='_blank' className="nav-link"  aria-current="page">Contact us</a>
                            </li>
                        </ul>
                    </div>
                    <AuthModal
                        show={openAuthModal ? true : show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    />
                    <Button variant="btn primary-btn ms-5 account-btn" onClick={handleShow}>
                        Login or Signup
                    </Button>
                </>
            )}
        </>
    );
}

export default NavBar;
